<template>
<!--  <div id="page-wrapper">-->
  <div id="app">
    <nav-header ref="header" />
    <router-view/>
    <br>
    <br>
    <nav-footer ref="footer" />
    <a id="go-top">
      <i class="fa fa-arrow-circle-o-up" />
    </a>
  </div>
</template>

<script>
import NavHeader from './components/NavHeader.vue'
import NavFooter from './components/NavFooter.vue'
export default {
  components: {
    NavHeader,
    NavFooter,
  },
  watch: {
    '$route': {
      handler: (to, from) => {
        document.title = to.meta.title || 'Biuro rachunkowe Poznań "STORNO"';
      },
      immediate: true
    },
  },
}
</script>
<style lang="scss">
  @import "./scss/style.scss";
</style>
