<template>
  <footer>
    <div id="footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
            <div class="widget widget-text">
              <h3 class="widget-title">
                MENU
              </h3>
              <router-link to="/">
                Strona główna
              </router-link>
              <br>
              <router-link to="/o-nas">
                O nas
              </router-link>
              <br>
              <router-link to="/oferta">
                Oferta
              </router-link>
              <br>
              <router-link to="/cennik">
                Cennik
              </router-link>
              <br>
              <router-link to="/sprawdzenie-kontrahenta">
                Sprawdzenie kontrahenta
              </router-link>
              <br>

              <router-link to="/linki">
                Linki
              </router-link>
              <br>
              <router-link to="/kontakt">
                Kontakt
              </router-link>
            </div>
          </div>
          <!-- col -->
          <div class="col-sm-3">
            <div class="widget widget-latest-news">
              <h3 class="widget-title">
                Adres
              </h3>
              <p>
                ul.Główna 46
                <br>61-007 Poznań
              </p>
              <p>
                os. Dębina 2
                <br>61-450 Poznań
              </p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="widget widget-latest-news">
              <h3 class="widget-title">
                Telefon
              </h3>
              <p>601-757-446<br>507-165-633</p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="widget widget-contact">
              <h3 class="widget-title">
                E-mail
              </h3>
              <a href="mailto:storno@biurostorno.pl">storno@biurostorno.pl</a>
              <br>
              <a href="mailto:biuro@biurostorno.pl">biuro@biurostorno.pl</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->

    <div id="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="widget widget-text">
              <p class="last text-center text-uppercase">
                &copy; Copyright © {{ year }}.
                <span class="text-primary">Biuro STORNO</span>
                <span class="text-lowercase">.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'NavFooter',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
