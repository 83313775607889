<template>
  <header class="bottom-shadow fake-size">
    <div class="container">
      <div class="row">
        <div class="col-sm-2">
          <router-link id="logo" class="logo" to="/">
            <h1>
              STORNO
              <br>
              <span>biuro rachunkowe poznań</span>
            </h1>
          </router-link>
        </div>
        <div class="col-sm-10">
          <nav>
            <a id="mobile-menu-button" href="#">
              <i class="fa fa-bars" />
            </a>
            <ul id="menu" class="menu clearfix">
              <li :class="{ 'active' :getClass('o-nas') }">
                <router-link to="/o-nas">
                  O nas
                </router-link>
              </li>
              <li :class="{ 'active' :getClass('oferta') }">
                <router-link to="/oferta">
                  Oferta
                </router-link>
              </li>
              <li :class="{ 'active' :getClass('cennik') }">
                <router-link to="/cennik">
                  Cennik
                </router-link>
              </li>
              <li :class="{ 'active' :getClass('sprawdzenie-kontrahenta') }">
                <router-link to="/sprawdzenie-kontrahenta">
                  Sprawdzenie kontrahenta
                </router-link>
              </li>
              <li :class="{ 'active' :getClass('linki') }">
                <router-link to="/linki">
                  Linki
                </router-link>
              </li>
              <li :class="{ 'active' :getClass('kalkulatory') }">
                <router-link to="/kalkulatory">
                  Kalkulatory
                </router-link>
              </li>
              <li :class="{ 'active' :getClass('kontakt') }">
                <router-link to="/kontakt">
                  Kontakt
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'NavHeader',
  methods: {
    getClass (name) {
      console.log(this.$route.name)
      return name === this.$route.name
    }
  }
}
</script>
