import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const keywords = 'biuro rachunkowe poznań, biuro rachunkowe, biuro rachunkowe w poznaniu, biuro księgowe poznań, biuro usług księgowych, biuro księgowe, biuro usług księgowych, księgowy, księgowa, księgowi, poznań, zeznania roczne, pity poznań, doradca podatkowy';
const description = 'Biuro Rachunkowe Poznań "Storno" - kompleksowa obsługa firm oraz świadczenie usług dla osób nie prowadzących działalności gospodarczej.';
const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    meta: {
      title: 'Strona główna - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: '/cennik',
    name: 'Cennik',
    component: () => import('../views/Cennik.vue'),
    meta: {
      title: 'Cennik - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: '/kalkulatory',
    name: 'Kalkulatory',
    component: () => import('../views/Kalkulatory.vue'),
    meta: {
      title: 'Kalkulator - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue'),
    meta: {
      title: 'Kontakt - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: '/linki',
    name: 'Linki',
    component: () => import('../views/Linki.vue'),
    meta: {
      title: 'Linki - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: '/oferta',
    name: 'Oferta',
    component: () => import('../views/Oferta.vue'),
    meta: {
      title: 'Oferta - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: '/o-nas',
    name: 'ONas',
    component: () => import('../views/ONas.vue'),
    meta: {
      title: 'O nas - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: '/sprawdzenie-kontrahenta',
    name: 'SprawdzenieKontrahenta',
    component: () => import('../views/SprawdzenieKontrahenta.vue'),
    meta: {
      title: 'Sprawdzenie kontrahenta - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  },
  {
    path: "*",
    name: '404',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      title: '404 - Biuro rachunkowe Poznań "STORNO"',
      metaTags: [
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        }
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
